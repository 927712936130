import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { fetchExchangeRate } from './services/currencyService';

const ConverterContainer = styled.form`
  width: 520px;
  max-width: 100%;
  padding: 32px;
  border-radius: 22px; // 8px + 20px to account for the border
  color: #282c34;
  box-sizing: border-box;
  background-color: #ffffff;
`;

const InputGroup = styled.div`
  margin-bottom: 20px;
`;

const StyledInputGroup = styled(InputGroup)`
  border: 2px solid black !important;
  border-radius: 12px !important;
  background-color: white;
  padding: 10px !important;
  &.error {
    border: 2px solid #a1271a !important;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px !important;
  font-weight: 700; // Changed from 400 to 700 to make it bold
  font-size: 12px !important;
  text-align: left !important;
`;

const Input = styled.input`
  width: 100% !important;
  padding: 6px !important;
  border: none !important;
  font-weight: 800 !important	;
  font-size: 28px !important;
  box-sizing: border-box;
  box-shadow: none !important;
`;

const ResultContainer = styled.div`
  margin: 20px 0 0px;
  padding: 16px 16px;
  border-radius: 12px;
  font-size: 12px;
  background-color: #ffffff;
`;

const ResultRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  flex-direction: row-reverse;
`;

const ResultValue = styled.span`
  position: relative;
  padding-left: 0px;
  display: flex;
  align-items: center;
`;
const MinusSpan = styled.span`
position: relative;
width: 16px;
height: 16px;
display: inline-block;
margin-right: 10px;
&:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    
    background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='8' fill='%23EDEEED'/%3E%3Crect x='4' y='7.25' width='8' height='1.5' rx='0.75' fill='%23212721'/%3E%3C/svg%3E%0A");
    background-size: contain;
    width: 16px;
    height: 16px;
    display: block;
  }
`;
const EqualsSpan = styled.span`
position: relative;
width: 16px;
height: 16px;
display: inline-block;
margin-right: 10px;
&:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    
	background-image: url("data:image/svg+xml,%0A%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='8' fill='%23EDEEED'/%3E%3Crect x='4' y='5.5' width='8' height='1.5' rx='0.75' fill='%23212721'/%3E%3Crect x='4' y='9' width='8' height='1.5' rx='0.75' fill='%23212721'/%3E%3C/svg%3E%0A");
    background-size: contain;
    width: 16px;
    height: 16px;
    display: block;
  }
`;
const DivideSpan = styled.span`
position: relative;
width: 16px;
height: 16px;
display: inline-block;
margin-right: 10px;
&:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0px;
    
	background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.178711' y='0.474121' width='20.7382' height='20.7382' rx='10.3691' fill='%23EDEEED'/%3E%3Ccircle cx='10.5481' cy='7.21459' r='1.29614' fill='%23212721'/%3E%3Crect x='5.2168' y='9.84375' width='10.6628' height='1.99927' rx='0.999633' fill='%23212721'/%3E%3Ccircle cx='10.5481' cy='14.4719' r='1.29614' fill='%23212721'/%3E%3C/svg%3E%0A");
    background-size: contain;
    width: 16px;
    height: 16px;
    display: block;
  }
`;

const CTAButton = styled.button`
  background-color: rgb(28, 73, 81);
  color: white;
  border: none;
  padding: 15px 20px !important; // Increased top and bottom padding
  border-radius: 16px !important;
  font-size: 16px !important;
  font-weight: 700 !important; // Made the text bolder
  cursor: pointer;
  margin: 20px auto 0; // Increased top margin
  display: block;
  text-decoration: none;
  width: 100%;
  font-weight: 600 !important;
  font-family: "haffer",sans-serif;
    letter-spacing: 1px;
  &:hover {
    background-color: rgb(28, 73, 81);
	color: white;
  }
`;

const ArrivalDate = styled.p`
  font-style: italic;
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
`;
const CurrentDate = styled.p`
  font-style: italic;
  margin-top: 15px;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  margin-bottom: 16px;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  padding-right: 80px !important;
  padding-left: 30px !important; // Add left padding for currency symbol
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &:focus::placeholder {
    color: transparent;
  }
`;

const CurrencyIndicator = styled.div`
  position: absolute;
  right: 12px;
  top: -16px;
  display: flex;
  align-items: center;
  pointer-events: none;
  background-color: white; // Changed from rgb(237, 238, 237) to white
  padding: 10px;
  border-radius: 32px;
`;

const Flag = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 8px;
  background-size: cover;
  background-position: center;
`;

const CurrencyCode = styled.span`
  font-weight: bold;
  font-size: 14px;
  margin-left: 10px;
`;
const LiveRateSpan = styled.span`
  background-color: #D8F3E2;
  border-radius: 12px;
  padding-left: 6px;
  padding-right: 6px;
`;
const CurrencySymbol = styled.span`
  position: absolute;
  left: 12px;
  font-weight: bold;
  font-size: 24px;
  pointer-events: none;
`;
const ErrorText = styled.span`
  color: #a1271a;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ErrorLogo = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.35938 13.3887C3.01953 13.3887 2.72461 13.3145 2.47461 13.166C2.22461 13.0176 2.03125 12.8184 1.89453 12.5684C1.76172 12.3184 1.69531 12.041 1.69531 11.7363C1.69531 11.4395 1.77344 11.1562 1.92969 10.8867L6.46484 2.98242C6.61719 2.70508 6.82227 2.49609 7.08008 2.35547C7.33789 2.21484 7.60742 2.14453 7.88867 2.14453C8.16992 2.14453 8.4375 2.21484 8.69141 2.35547C8.94531 2.49219 9.15234 2.70117 9.3125 2.98242L13.8477 10.8867C13.9258 11.0195 13.9844 11.1582 14.0234 11.3027C14.0625 11.4473 14.082 11.5918 14.082 11.7363C14.082 12.041 14.0137 12.3184 13.877 12.5684C13.7402 12.8184 13.5469 13.0176 13.2969 13.166C13.0508 13.3145 12.7578 13.3887 12.418 13.3887H3.35938ZM7.89453 9.375C8.24219 9.375 8.41992 9.19336 8.42773 8.83008L8.52148 5.88867C8.5293 5.70898 8.47266 5.5625 8.35156 5.44922C8.23047 5.33203 8.07617 5.27344 7.88867 5.27344C7.69727 5.27344 7.54297 5.33203 7.42578 5.44922C7.30859 5.5625 7.25391 5.70703 7.26172 5.88281L7.34375 8.83594C7.35547 9.19531 7.53906 9.375 7.89453 9.375ZM7.89453 11.3906C8.08984 11.3906 8.25781 11.3281 8.39844 11.2031C8.53906 11.0781 8.60938 10.918 8.60938 10.7227C8.60938 10.5312 8.53906 10.3711 8.39844 10.2422C8.25781 10.1133 8.08984 10.0488 7.89453 10.0488C7.69531 10.0488 7.52539 10.1133 7.38477 10.2422C7.24414 10.3711 7.17383 10.5312 7.17383 10.7227C7.17383 10.918 7.24414 11.0781 7.38477 11.2031C7.5293 11.3281 7.69922 11.3906 7.89453 11.3906Z" fill="#AA2C1C"/>
	</svg>
);

const IndiaFlag = () => (
  <svg width="24" height="24" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_199_10086)">
      <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="white"/>
      <path d="M31.9997 25.3334C35.6663 25.3334 38.6663 28.3334 38.6663 32C38.6663 35.6667 35.6663 38.6667 31.9997 38.6667C28.333 38.6667 25.333 35.6667 25.333 32C25.333 28.3334 28.333 25.3334 31.9997 25.3334Z" fill="white"/>
      <path d="M32 25.3333C35.6667 25.3333 38.6667 28.3333 38.6667 32C38.6667 35.6667 35.6667 38.6667 32 38.6667C28.3333 38.6667 25.3333 35.6667 25.3333 32C25.3333 28.3333 28.3333 25.3333 32 25.3333ZM32 24C27.6 24 24 27.6 24 32C24 36.4 27.6 40 32 40C36.4 40 40 36.4 40 32C40 27.6 36.4 24 32 24Z" fill="#231D9A"/>
      <path d="M1.7998 21.3333H62.1331C57.7998 8.93333 45.9331 0 31.9998 0C18.0665 0 6.1998 8.93333 1.7998 21.3333Z" fill="#FF7700"/>
      <path d="M62.1998 42.6666H1.7998C6.1998 55.0666 18.0665 64 31.9998 64C45.9331 64 57.7998 55.0666 62.1998 42.6666Z" fill="#005B13"/>
      <path d="M35.9867 32.1163C36.0453 29.908 34.3027 28.0704 32.0944 28.0118C29.8862 27.9532 28.0485 29.6958 27.9899 31.9041C27.9313 34.1123 29.6739 35.95 31.8822 36.0086C34.0904 36.0672 35.9281 34.3246 35.9867 32.1163Z" fill="#231D9A"/>
    </g>
    <defs>
      <clipPath id="clip0_199_10086">
        <rect width="64" height="64" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

const CanadaFlag = () => (
  <svg width="24" height="24" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_199_10263)">
      <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="white"/>
      <path d="M0 32.0004C0 43.8671 6.46667 54.2004 16 59.7338V4.26709C6.46667 9.80042 0 20.1338 0 32.0004Z" fill="#F0263C"/>
      <path d="M48 4.26709V59.6671C57.5333 54.2004 64 43.8671 64 32.0004C64 20.1338 57.5333 9.80042 48 4.26709Z" fill="#F0263C"/>
      <path d="M46.6663 32.0002L43.9997 30.6668L45.333 26.6668L41.333 28.0002L39.9997 25.3335L35.9997 29.3335L37.333 21.3335L34.733 22.6668L31.9997 17.3335L29.2663 22.6668L26.6663 21.3335L27.9997 29.3335L23.9997 25.3335L22.6663 28.0002L18.6663 26.6668L19.9997 30.6668L17.333 32.0002L25.333 38.6668L23.9997 41.3335L30.6663 40.0002V46.6668H33.333V40.0002L39.9997 41.3335L38.6663 38.6668L46.6663 32.0002Z" fill="#F0263C"/>
    </g>
    <defs>
      <clipPath id="clip0_199_10263">
        <rect width="64" height="64" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

const DisabledInputGroup = styled(InputGroup)`
  background-color: #f0f0f0;
  border: none;
  border-radius: 12px;
  padding: 10px;
`;

const DisabledInput = styled(Input)`
  background-color: transparent !important;
  color: #282c34;
  cursor: not-allowed;
  padding-right: 80px !important;
  padding-left: 30px !important; // Add left padding for currency symbol

`;

const CurrencyConverter = () => {
	const [amountINR, setAmountINR] = useState('500000');
	const [amountCAD, setAmountCAD] = useState('');
	const [convertedAmount, setConvertedAmount] = useState('');
	const [rate, setRate] = useState(0);
	const [arrivalDate, setArrivalDate] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [beaconChargeRate, setBeaconChargeRate] = useState(0.015);
	const [currentDateData, setCurrentDateData] = useState('');
	const [inputError, setInputError] = useState();
	const [hasClearedOnce, setHasClearedOnce] = useState(false);
	useEffect(() => {
		const fetchRate = async () => {
			setIsLoading(true);
			setError(null);
			const fetchedRate = await fetchExchangeRate('INR', 'CAD');
			console.log(`fetchedRate: ${fetchedRate}`);
			if (fetchedRate) {
				setRate(fetchedRate);
				//calculateConversion('500000', 'INR');
			} else {
				setError('Failed to fetch exchange rate. Using default rate. Please try again later.');
				//setRate(0.016); // Fallback to default rate
				//calculateConversion('10000', 'CAD');				
			}
			setIsLoading(false);
		};

		fetchRate();
		updateArrivalDate();
		const currentDate = new Date().toLocaleDateString('en-US', {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		});
		setCurrentDateData(currentDate);
	}, []);

	useEffect(() => {
		if (rate !== 0) {
			calculateConversion('500000', 'INR');
		}
	}, [rate]);

	const updateArrivalDate = () => {
		const now = new Date();
		let arrivalDate = new Date(now.getTime() + 48 * 60 * 60 * 1000); // Add 48 hours

		// If the arrival date falls on a weekend, move it to the next Monday
		while (arrivalDate.getDay() === 0 || arrivalDate.getDay() === 6) {
			arrivalDate.setDate(arrivalDate.getDate() + 2);
		}

		// If the current day is Friday and it's past 12 PM, move to Tuesday
		if (now.getDay() === 5 && now.getHours() >= 12) {
			arrivalDate.setDate(arrivalDate.getDate() + 1); // Move to Wednesday
		}

		// Format the date
		const formattedDate = arrivalDate.toLocaleDateString('en-US', {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		});

		setArrivalDate(formattedDate);
	};

	const formatNumberWithCommas = (x, currency) => {
		if (currency === 'INR') {
			return formatINR(x);
		}
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const formatINR = (x) => {
		x = x.toString();
		let lastThree = x.substring(x.length - 3);
		let otherNumbers = x.substring(0, x.length - 3);
		if (otherNumbers !== '')
			lastThree = ',' + lastThree;
		let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
		return res;
	};

	const removeCommas = (str) => {
		return str.replace(/,/g, '');
	};

	const calculateConversion = (value, currency) => {


		// console.log(`calculateConversion(${value}, ${currency})`);
		if (value && !isNaN(removeCommas(value))) {
			handleCalculatorInteraction();

			const numericValue = parseFloat(removeCommas(value));
			if (numericValue < 10000) {
				setAmountCAD('');
				setConvertedAmount('');
				setInputError('under');
				return;
			}	
			else if (numericValue > 5000000) {
				setAmountCAD('');
				setConvertedAmount('');
				setInputError('over');
				return;
			}else{
				setInputError(null);
			}


			const bankChargeINR = 600;
			//const taxRate = 0; // 0.5% tax
			//const beaconChargePercent = beaconChargeRate; // 1.5% charge
			const beaconExchangeRate = rate * (1 - beaconChargeRate);
			// console.log(`beaconExchangeRate: ${beaconExchangeRate}`);
			if (currency === 'INR') {
				//const totalTaxes = Math.round(numericValue * taxRate);
				//const beaconChargeINR = Math.round(numericValue * beaconChargePercent) * rate;
				const totalAmountToConvert = Math.round((numericValue - bankChargeINR)*beaconExchangeRate);

				const convertedValue = Math.round(totalAmountToConvert);
				
				console.log(`${numericValue} INR - ${bankChargeINR} CAD x ${beaconExchangeRate.toFixed(6)} = ${convertedValue} CAD`);

				setConvertedAmount(convertedValue.toString());
				setAmountCAD(formatNumberWithCommas(convertedValue, 'CAD'));
				setAmountINR(formatNumberWithCommas(numericValue, 'INR'));
			}
			// } else {
			// 	const convertedValue = Math.round(numericValue / beaconExchangeRate);
			// 	//const taxes = Math.round(convertedValue * taxRate);
			// 	const senderAmount = Math.round(convertedValue + bankChargeINR);

			// 	console.log(`${numericValue} CAD / ${beaconExchangeRate.toFixed(6)} + ${bankChargeINR}(bank) INR  = ${senderAmount} INR`);

			// 	setConvertedAmount(formatNumberWithCommas(numericValue, 'CAD'));
			// 	setAmountINR(formatNumberWithCommas(senderAmount, 'INR'));
			// 	setAmountCAD(formatNumberWithCommas(numericValue, 'CAD'));
			// }
		} else {
			setConvertedAmount('');
			setAmountCAD('');
			setAmountINR('');
		}
	};

	const calculateTotalTaxes = (value) => {
		return Math.round(value * 0.00); // 0.5% tax
	};

	const formatWithSymbol = (value, symbol) => {
		return `${symbol}${value}`;
	};

	const handleAmountChange = (_value, currency) => {
		const value = _value.replace(/[^0-9,]/g, '');
		const numericValue = removeCommas(value);
		const formattedValue = formatNumberWithCommas(numericValue, currency);
		if (currency === 'INR') {
			setAmountINR(formattedValue);
		} else {
			setAmountCAD(formattedValue);
		}
	};
	const handleCalculatorInteraction = () => {
		// Fire a custom event when the user interacts with the calculator
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
		  event: 'calculator_interaction', // Event name
		  interaction_type: 'amount_changed',     // Custom event data (optional)
		  component: 'calculator'          // Additional data (optional)
		});
				
	};

	const calculateComparePrice = () => {
		const totalAmount = parseFloat(removeCommas(amountINR));
		return formatNumberWithCommas(Math.round(totalAmount * rate), 'CAD');
	};

	const handleInputFocus = (e) => {
		if (!hasClearedOnce) {
			e.target.value = '';
			setAmountINR('');
			setAmountCAD('');
			setConvertedAmount('');
			setHasClearedOnce(true);
		}
	};

	const handleCalculateClick = (e) => {
		e.preventDefault();
		calculateConversion(amountINR, 'INR');
	};

	return (
		<ConverterContainer onSubmit={handleCalculateClick}>
			{isLoading ? (
				<p>Loading exchange rate...</p>
			) : error ? (
				<p>{error}</p>
			) : (
				<>
					{/* <CurrentDate>Current Date: {currentDateData}</CurrentDate> */}
					<StyledInputGroup className={inputError ? 'error' : ''}>
						<Label>You send from India</Label>
						<InputWrapper>
							<CurrencySymbol>₹</CurrencySymbol>
							<StyledInput
								type="text"
								value={amountINR}
								onChange={(e) => handleAmountChange(e.target.value, 'INR')}
								onFocus={handleInputFocus}
								placeholder="Enter amount"
								className={inputError ? 'error' : ''}
							
							/>
							<CurrencyIndicator style={{backgroundColor: '#EDEEED'}}>
								<IndiaFlag />
								<CurrencyCode>INR</CurrencyCode>
							</CurrencyIndicator>
						</InputWrapper>
					</StyledInputGroup>
					{inputError && <ErrorText><ErrorLogo />{inputError == 'over' ? 'You\'ve exceed the maximum transfer limit of ₹50,00,000' : 'Please enter a minimum of ₹10,000'}</ErrorText>}

					<ResultContainer>						
						<ResultRow>
							<span>Bank charge</span>
							<ResultValue><MinusSpan /><strong>₹ {formatINR(600)} INR</strong></ResultValue>
						</ResultRow>
						<ResultRow>
							<span>Beacon charges</span>
							<ResultValue><MinusSpan /><strong>₹ 0 INR</strong></ResultValue>
						</ResultRow>
						
						<hr style={{margin: '0px 0px 16px'}} />
						<ResultRow>
							<span><strong>Total amount we'll convert</strong></span>
							<ResultValue><EqualsSpan /><strong>₹ {formatINR(Math.round(parseFloat(removeCommas(amountINR)) - 600))} INR</strong></ResultValue>
						</ResultRow>
						<ResultRow>
							<LiveRateSpan>Live Rate: 1 CAD = {(1 / (rate*(1-beaconChargeRate))).toFixed(4)}</LiveRateSpan>
							<ResultValue><DivideSpan /><strong>{(1 / (rate*(1-beaconChargeRate))).toFixed(4)}</strong></ResultValue>
						</ResultRow>
						<ResultRow>
							<span>&nbsp;</span>
							<ResultValue> All Taxes will be shown and added at the time of transaction.</ResultValue>
						</ResultRow>
					</ResultContainer>
					
					<DisabledInputGroup>
						<Label>Recipient gets in CAD</Label>
						<InputWrapper>
							<CurrencySymbol>$</CurrencySymbol>
							<DisabledInput
								type="text"
								value={amountCAD}
								disabled
								placeholder="0"
							/>
							<CurrencyIndicator>
								<CanadaFlag />
								<CurrencyCode>CAD</CurrencyCode>
							</CurrencyIndicator>
						</InputWrapper>
					</DisabledInputGroup>
					
					<ArrivalDate>Should arrive by: {arrivalDate}</ArrivalDate>
					<CTAButton type="submit" onClick={handleCalculateClick}>Calculate</CTAButton>
				</>
			)}
		</ConverterContainer>
	);
};

export default CurrencyConverter;